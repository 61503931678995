/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.5.1/dist/jquery.min.js
 * - /npm/bootstrap@4.4.1/dist/js/bootstrap.min.js
 * - /npm/flatpickr@4.6.3/dist/flatpickr.min.js
 * - /npm/flatpickr@4.6.3/dist/l10n/ar.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/aos@2.3.4/dist/aos.min.js
 * - /npm/chart.js@2.9.3/dist/Chart.min.js
 * - /npm/select2@4.0.13/dist/js/select2.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
